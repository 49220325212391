<template>
  <div class="supploerList">
    <!-- 设备数量监控 -->
    <div class="warpFun meRow">
      <div class="recordList">
        <div class="recordText">
          <span>{{ quipmenList.bindCount }}</span>
          <p>绑定摄像头</p>
        </div>
        <div class="recordImg"></div>
      </div>
      <div class="recordList">
        <div class="recordText">
          <span>{{ quipmenList.onlineCount }}</span>
          <p>在线摄像头</p>
        </div>
        <div class="recordImgs"></div>
      </div>
    </div>
    <div class="tabshow">
      <div class="operation">
        <p>我的摄像头</p>
        <i></i>
        <div class="ActionButtons">
          <el-row class="meRon">
            <el-col :span="4">
              <el-input
                v-model="DeviceName"
                clearable
                placeholder="硬件ID、设备名称"
              ></el-input>
            </el-col>

            <!-- <el-col :span="2">
              <el-select v-model="OnlineStatus" placeholder="在线状态">
                <el-option
                  v-for="item in OnlineStatusList"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-col> -->
            <el-col :span="3" class="inputs">
              <el-button @click="getQuery" type="primary">查询</el-button>
              <el-button @click="getReset" type="primary">重置</el-button>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="tab">
        <el-table
          max-height="630"
          :data="tableData"
          style="width: 100%"
          stripe
          :header-cell-style="{
            'text-align': 'center',
            background: '#EDEEF5',
            color: '#333333',
          }"
          :cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column
            type="selection"
            width="55"
            align="center"
          ></el-table-column>
          <el-table-column prop="aliEquipmentNo" label="硬件ID">
          </el-table-column>
          <el-table-column
            prop="equipmentName"
            label="摄像头名称"
          ></el-table-column>
          <el-table-column prop="createDate" label="绑定时间">
          </el-table-column>
          <el-table-column prop="updateDate" label="更新时间"></el-table-column>
          <el-table-column prop="aliStatus" label="设备状态">
            <template slot-scope="scope">
              <span v-if="scope.row.aliStatus == 'off'">离线</span>
              <span class="isColor" v-if="scope.row.aliStatus == 'on'"
                >在线</span
              >
              <span class="isColor" v-if="scope.row.aliStatus == 'failed'"
                >锁定</span
              >
              <span class="isColor" v-if="scope.row.aliStatus == 'new'"
                >未注册</span
              >
            </template>
          </el-table-column>

          <el-table-column prop="value1" label="操作" width="200">
            <template slot-scope="scope">
              <el-button type="text" @click="editor(scope.row)">编辑</el-button>
              <el-button type="text" @click="getBoardSet(scope.row)"
                >查看</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="block">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 30, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <!-- //编辑设备 -->
    <el-dialog
      title="编辑设备名称"
      :visible.sync="dialogVisibleEditor"
      width="500px"
      :before-close="handleCloseEditors"
      :modal-append-to-body="false"
    >
      <el-row style="display: flex; justify-content: center">
        <el-col :span="4" style="line-height: 4rem">设备名称:</el-col>
        <el-col :span="8">
          <el-input v-model="editorBoard" placeholder="输入设备名称"></el-input>
        </el-col>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloseEditors">取 消</el-button>
        <el-button type="primary" @click="examineSupplier">确 定</el-button>
      </span>
    </el-dialog>
    <!-- //-->
    <el-dialog
      title="监控视频"
      :visible.sync="dialogVisibleSet"
      width="500px"
      :before-close="handleCloseSet"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      @open="getOpen"
    >
      <!-- 播放视频 -->
      <div class="videoWarp">
        <!-- <el-button @click="getViode">点击播放</el-button> -->
        <video
          autoplay
          controls
          width="100%"
          height="500"
          id="videoElement"
        ></video>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  querySmartMonitorEquipment,
  editEquipmentName,
  monitorEquipment,
} from "@/api/SmartMonitor/SmartMonitor";
import flvjs from "flv.js";
export default {
  components: {
    "el-image-viewer": () =>
      import("element-ui/packages/image/src/image-viewer"),
  },
  data() {
    return {
      srcList: [], //大图
      state: "", //状态
      tableData: [],
      keyword: "", //关键字
      pageSize: 10, //每页显示
      currentPage: 1, //当前页
      total: 0,
      shopNo: this.$store.getters.shopNo, //店铺编号
      shopType: this.$store.getters.shopType, //店铺编号
      shopName: this.$store.getters.shopName,
      merchantNo: this.$store.getters.userInfo.merchantNo,
      multipleSelection: [],
      carouselList: [],
      // ====
      marginLeft: 30,
      // ========
      value: "",

      spectacularsList: [], //看板
      OnlineStatusList: [
        //在线状态
        {
          name: "离线",
          value: 0,
        },
        {
          name: "在线",
          value: 1,
        },
      ],
      DisabledStateList: [
        {
          name: "禁用",
          value: 0,
        },
        {
          name: "启用",
          value: 1,
        },
      ], //禁用状态
      pectaculars: null, //看板
      OnlineStatus: null, //在线状态
      DisabledState: null, //禁用状态
      DeviceName: "", //设备名称
      // ======= 设备
      quipmenList: {
        bindCount: 0,
        onlineCount: 0,
      },
      // ============
      dialogVisibleEditor: false,
      editorBoard: "", //编辑看板

      dialogVisibleSet: false, //设置看板
      editorSet: "",
      editorSetList: [],
      getBoardSetId: null,

      // ===========
      // 看板展示
      dialogVisibleShow: false,
      tableDataShowList: [], //已设置
      tableDataShowunSetVoList: [], //未设置

      spectacularsNo: null, //看板编号
      spectacularsData: null, //看板数据
      isPreview: false,
      aliEquNoId:"",//

      flvPlayer: null,//播放

    };
  },
  mounted() {
      this.$nextTick(() => {
    });
  },
  methods: {
    getViode() {
      if(this.videoUrl == "" || this.videoUrl ==null) return this.$message.error("没有可播放的")
      console.log(this.videoUrl);
      if (flvjs.isSupported()) {
        var videoElement = document.getElementById("videoElement");
        var flvPlayer = flvjs.createPlayer({
          type: "flv",
          // url: 'http://1011.hlsplay.aodianyun.com/demo/game.flv'
          // url: 'https://www.huya.com/ff0c93cd-90f6-4619-a5e3-179cb313648b'
          url: this.videoUrl,
        });
        flvPlayer.attachMediaElement(videoElement);
        flvPlayer.load();
        flvPlayer.play();
      }
    },
    getOpen() {
      let obj = {
        params: {
          aliEquipmentNo: this.getBoardSetId,
          outProtocol: "flv",
        },
      };
      monitorEquipment(obj).then((res) => {
        console.log(res);
        this.videoUrl = res.data.data.url;
        this.getViode()
      });
    },

    // 预览
    preview() {
      this.isPreview = true;
      console.log(this.spectacularsData.spectacularsImg);
      this.srcList.push(this.spectacularsData.spectacularsImg);
    },
    async setUp(row) {
      console.log(row);
      //设置看板
      let obj = {
        params: {
          id: row.id,
          spectacularsNo: this.spectacularsData.spectacularsNo,
          merchantNo: this.merchantNo,
        },
      };
      const { data: res } = await setSpectacularsEquipment(obj);
      this.$message.success(res.msg);

      this.getShow(this.spectacularsData);
    },
    async getCancelSetup(row) {
      //取消设置
      let obj = {
        params: {
          id: row.id,
          merchantNo: this.merchantNo,
        },
      };
      const { data: res } = await unSetSpectacularsEquipment(obj);
      this.$message.success(res.msg);
      this.getShow(this.spectacularsData);
    },

    // ==========
    handleCloseShow() {
      this.dialogVisibleShow = false;
    },
    async setCommodityShow(row) {
      // this.spectaculars =row.id
      // this.spectacularsNo = row.spectacularsNo
      console.log(row);
      this.spectacularsData = row;
      this.dialogVisibleShow = true;
      this.getShow(row);

      //设置看板展示
    },
    async getShow(num) {
      console.log(num);
      let obj = {
        params: {
          id: num.id,
          merchantNo: this.merchantNo,
          spectacularsNo: num.spectacularsNo,
        },
      };
      const { data: res } = await querySpectacularsEquipmentSet(obj);
      console.log(res);
      this.tableDataShowList = res.data.setVo;
      this.tableDataShowunSetVoList = res.data.unSetVo;
    },
    // ============
    handleCloseSet() {
      this.dialogVisibleSet = false;
      this.editorSet = "";
    },
    getBoardSet(row) {
      this.dialogVisibleSet = true;
      this.getBoardSetId = row.aliEquipmentNo;
    },

    // =======
    handleCloseEditors() {
      this.handleCloseEditor = "";
      this.editorBoard = "";
      this.dialogVisibleEditor = false;
    },
    editor(row) {
      console.log(row);
      this.EditoId = row.id;
      this.dialogVisibleEditor = true;
      this.aliEquNoId = row.aliEquipmentNo
    },
    // ==== 编辑设备

    // 查询
    getQuery() {
      this.getSupplierList();
    },
    // 重置
    getReset() {
      this.DeviceName = "";
      this.pectaculars = "";
      this.OnlineStatus = "";
      this.DisabledState = "";
      this.editorBoard = "";
      this.getSupplierList();
    },
    async setCommodity(row) {
      //设置看板
    },

    async examineSupplier() {
      if (this.editorBoard == "" || this.editorBoard == null)
        return this.$message.error("名称不能为空");
      //编辑
      let obj = {
        params: {
          id: this.EditoId,
          eqName: this.editorBoard,
          aliEquNo:this.aliEquNoId,
        },
      };
      const { data: res } = await editEquipmentName(obj);

      this.handleCloseEditors(); //重置
      this.getSupplierList(); //刷新
      this.$message.success(res.msg);
    },
    async compileSupplier(row) {
      //禁用 、 启用
      let obj = {
        params: {
          equipmentNo: row.equipmentNo,
        },
      };
      const { data: res } = await updateUseSpectacularsEquipment(obj);
      console.log(res);
      this.$message.success(res.msg);
      this.getSupplierList();
    },
    // =
    // 获取展示看板列表
    async getBoard() {
      let obj = {
        params: {
          isOnline: 1,
        },
      };
      const { data: res } = await querySpectacularsPage(obj);
      console.log(res);
      this.carouselList = res.data.data;
    },

    // 左切换
    getBtnLeft() {
      let _width = this.$refs.warp.clientWidth;
      let tabRef = this.$refs.tabRef.clientWidth;
      let s1 = this.$refs.s1.clientWidth;
      console.log(_width);
      console.log(tabRef);
      console.log(s1);
      if (tabRef - _width > s1) {
        console.log("大于");
      } else {
        console.log("小于");
        console.log(tabRef - _width);
      }

      // if(){

      // }
    },
    getBtnRight() {
      this.$refs.tabRef.style.marginLeft = this.marginLeft + "px";
      let width = this.$refs.tabRef.style.marginLeft;
      let add = this.$refs.s1.clientWidth;
      if (width) {
        let wen = parseInt(width.substr(0, width.length - 2));
        console.log(wen);
        this.marginLeft = wen + add;
        this.$refs.tabRef.style.marginLeft = this.marginLeft + "px";
      }
      console.log(width);
    },
    // ===============
    // 分页
    handleSizeChange(val) {
      this.pageSize = val;
      this.getSupplierList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getSupplierList();
    },
    //获取 摄像头列表
    getSupplierList() {
      let obj = {
        params: {
          page: this.currentPage, //
          limit: this.pageSize, //
          merchantNo: this.merchantNo,
          isOnline: this.OnlineStatus,
          isUse: this.DisabledState,
          equName: this.DeviceName,
          spectacularsNo: this.pectaculars,
        },
      };
      querySmartMonitorEquipment(obj).then((res) => {
        console.log(res);
        this.tableData = res.data.data.page.records;
        this.total = res.data.data.page.total;
        this.quipmenList.bindCount = res.data.data.bindNum;
        this.quipmenList.onlineCount = res.data.data.onlineNum;
      });
    },
  },

  created() {
    this.getSupplierList();

    // this.getBoard();
    // this.getquerySpectacularsList();
  },
  beforeRouteEnter(to, from, next) {
    if (to.params.ifFresh) {
      to.meta.keepAlive = false;
    } else {
      to.meta.keepAlive = true;
    }
    next();
  },
};
</script>

<style lang="less" scoped>
.warpFun {
  display: flex;
  // justify-content: space-between;
  background: #f6f7f8;
  margin-left: -1.8rem;
  min-width: 1280px;
  .recordList {
    width: 25%;
    height: 6.7rem;
    border-radius: 0.8rem;
    display: flex;
    justify-content: center;
    background: #fff;
    padding: 2rem 1.8rem;
    margin-left: 1.9rem;
    .recordText {
      width: 80%;
      height: 100%;
      span {
        font-size: 3.2rem;
        color: #3f82f0;
      }
      p {
        font-size: 1.6rem;
        color: #666666;
      }
    }
    .recordImg {
      width: 20%;
      height: 6.5rem;
      background: url("~@/assets/img/icon_a1.png") no-repeat center;
      background-size: 65%;
    }
    .recordImgs {
      width: 20%;
      height: 6.5rem;
      background: url("~@/assets/img/icon_a2.png") no-repeat center;
      background-size: 65%;
    }
  }
}
.boardList {
  // height: 27rem;
  border-radius: 0.3rem;
  background: #fff;
  min-width: 1280px;
  padding-bottom: 25px;
  .boardHeaer {
    p {
      color: #666666;
      font-size: 1.6rem;
      padding-top: 1.6rem;
      padding-left: 2rem;
    }
    i {
      display: inline-block;
      width: 96%;
      border-bottom: 1px solid #e2e2e2;
      margin-left: 2rem;
    }
  }
  .carousel {
    position: relative;
    overflow: hidden;
    transition: all 0.2;
    .btnLeft {
      width: 2.5rem;
      height: 2.5rem;
      position: absolute;
      top: 50%;
      left: 12px;
      border-radius: 50%;
      background: url("~@/assets/img/icon_a6.png") no-repeat;
      background-size: 100%;
    }
    .btnRight {
      width: 2.5rem;
      height: 2.5rem;
      position: absolute;
      top: 50%;
      right: 12px;
      border-radius: 50%;
      background: url("~@/assets/img/icon_a5.png") no-repeat;
      background-size: 100%;
    }
    .carouselList {
      overflow: hidden;
      width: 90%;
      height: 70%;
      // min-width: 1440px;
      transition: all 0 ease 0;
      margin: 0 auto;
      // box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px ;
    }
  }
}
.tabshow {
  margin-top: 1.5rem;
  min-width: 1280px;
  .operation {
    width: 100%;
    min-width: 1280px;
    p {
      font-size: 1.6rem;
      padding: 1.6rem 2rem;
      color: #333333;
    }
    i {
      display: block;
      width: 95%;
      height: 1px;
      background: #ebebeb;
      margin: 0 auto;
      padding: 0 2rem;
    }
    .ActionButtons {
      padding: 1.8rem 0rem;
    }
  }
}
.meRon {
  width: 100%;
  .el-col {
    margin-left: 2rem;
    .el-input {
      /deep/ .el-input__inner {
        line-height: 34px !important;
        height: 34px;
      }
    }
    .el-button {
      padding: 9px 20px !important;
      background: #498dfe !important;
    }
    .el-select {
      /deep/ .el-input__inner {
        height: 3.4rem;
        text-align: center;
        line-height: 3.4rem;
      }
      /deep/ .el-input__icon {
        line-height: 0;
      }
    }
  }
}
.isColor {
  color: #11a600;
}
.isColorRed {
  color: #df2c2c;
}
.ShowP {
  font-size: 1.8rem;
  color: #333333;
}
.tetx {
  .flexSp();
  .Shows {
    font-size: 1.8rem;
    color: #333333;
    cursor: pointer;
  }
}
.demo-image__preview {
  .el-image-viewer {
    width: 1280px !important;
  }
}
.table-warning {
  width: 20%;
  height: 30rem;
  margin: 10rem auto;
  background: url("~@/assets/new-icon/Group Copy@3x.png") no-repeat;
  background-size: 80%;
  position: relative;
}
.videoWarp {
  video {
    width: 100%;
    height: 100%;
    display: block;

  }
  /* .video-player {
    width: 50%;
  } */
}
</style>
